import {Behaviour, behaviours} from '../../global/scripts/behaviours/behaviour'
import {styledSelect} from '../molecule/form/src/styledSelect'

const wcBlocks: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const select = context.querySelector<HTMLSelectElement>('.woocommerce-ordering select')

        if (!select) {
            return
        }

        styledSelect.init(select)
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(wcBlocks)
