import {styledForm} from './src/styledForm'
import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'

const formBehaviour: Behaviour = (() => {
    const attach = (context: HTMLElement) => {
        context.querySelectorAll<HTMLFormElement>('.form').forEach((form) => {
            styledForm.initAll(form)
        })
    }

    return {
        attach,
    }
})()

jQuery(document).on('gform_post_render', () => {
    document.querySelectorAll<HTMLFormElement>('.form').forEach((form) => {
        styledForm.initAll(form)
    })
})

behaviours.addBehaviour(formBehaviour)
