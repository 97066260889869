import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'
import {Sticky} from 'sticky-ts'
import {variations} from './src/variations'

const BASE_CLASS = 'productHero'

const ProductHeroBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>(`.${BASE_CLASS}`)

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block, context))
    }

    const init = function (block: HTMLElement, context: HTMLElement) {
        const quantity = block.querySelector<HTMLElement>('.quantity')
        const quantityLabel = quantity && quantity.querySelector<HTMLLabelElement>('label')
        if (quantityLabel) {
            quantityLabel.classList.remove('screen-reader-text')
        }

        // Sticky
        new Sticky(`.${BASE_CLASS}__details`)

        variations(context)
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(ProductHeroBehaviour)
