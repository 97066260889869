export const variations = (context: HTMLElement) => {
    const variationSelectToButtons = function (select: HTMLSelectElement) {
        const selectParent = select.parentElement

        if (!selectParent) {
            return
        }

        const selectOptions = Array.from(select.options).filter((option) => option.value !== '')
        const tr = select.closest('tr')
        const label = tr?.querySelector('label')

        if (label) {
            selectParent.insertBefore(label, select)
        }

        // Create Wrapper
        const wrapper = document.createElement('div')
        wrapper.classList.add('wcOptionButtons')
        selectParent.insertBefore(wrapper, select)

        const onButtonClick = (e: Event) => {
            const button = e.target as HTMLElement

            // Remove active class from all buttons
            wrapper.querySelectorAll('.wcOptionButton').forEach((button) => {
                button.classList.remove('styledButton--active')
            })

            button.classList.add('styledButton--active')
            select.value = button.getAttribute('data-value') || ''
            jQuery(select).trigger('change')
        }

        // Create button for each option in select
        selectOptions.forEach((option) => {
            const button = document.createElement('div')
            button.innerHTML = option.innerHTML
            button.className = 'wcOptionButton styledButton styledButton--typeLine'
            button.classList.toggle('styledButton--active', option.selected)
            button.dataset.value = option.value
            button.addEventListener('click', onButtonClick)
            wrapper.appendChild(button)
        })

        select.style.display = 'none'

        calculateLargestButton(wrapper)
        window.addEventListener('resize', () => calculateLargestButton(wrapper))
    }

    const calculateLargestButton = (wrapper: HTMLElement) => {
        let largestButton = 0

        wrapper.style.display = 'block'

        wrapper.querySelectorAll('.wcOptionButton').forEach((button) => {
            const buttonWidth = button.clientWidth
            if (buttonWidth > largestButton) {
                largestButton = buttonWidth
            }
        })

        wrapper.style.display = 'grid'
        wrapper.style.setProperty('--largest-button', `${largestButton}px`)
    }

    context.querySelectorAll<HTMLSelectElement>('select').forEach((select) => {
        variationSelectToButtons(select)

        if (context.querySelectorAll('.wcOptionButtons').length === 1) {
            // Select first option if none selected
            if (select.value === '') {
                context.querySelector<HTMLDivElement>('.wcOptionButton')?.click()
            }

            // Hide reset button
            const resetButton = context.querySelector<HTMLButtonElement>('.reset_variations')
            if (resetButton) {
                resetButton.style.display = 'none'
            }
        }
    })
}
