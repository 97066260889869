export const paymentAndSendingMethods = (() => {
    const attach = (html: HTMLElement) => {
        const reviewOrderWrapper = html.querySelector('.woocommerce-checkout-review-order')
        const totalTable = html.querySelector('.woocommerce-checkout-review-order-table')

        if (!reviewOrderWrapper || !totalTable) {
            return
        }

        const getPaymentAndSendingWrapper = () => {
            const existingPaymentAndSendingWrapper = html.querySelector('.paymentAndSending')

            if (existingPaymentAndSendingWrapper) {
                return existingPaymentAndSendingWrapper
            }

            const paymentAndSendingWrapper = document.createElement('div')
            paymentAndSendingWrapper.classList.add('paymentAndSending')
            reviewOrderWrapper.append(paymentAndSendingWrapper)
            return paymentAndSendingWrapper
        }

        const getPaymentWrapper = (parent: Element) => {
            const existingPaymentWrapper = parent.querySelector('.paymentAndSending__payment')
            if (existingPaymentWrapper) {
                return existingPaymentWrapper
            }

            const paymentMethodWrapper = document.createElement('div')
            paymentMethodWrapper.classList.add('paymentAndSending__payment')
            paymentMethodWrapper.innerHTML = '<h2 class="paymentAndSending__title">Betaling</h2>'
            parent.append(paymentMethodWrapper)
            return paymentMethodWrapper
        }

        const getSendingWrapper = (parent: Element, empty = false) => {
            const existingSendingWrapper = parent.querySelector('.paymentAndSending__sending')

            if (existingSendingWrapper && empty) {
                parent.removeChild(existingSendingWrapper)
            } else if (existingSendingWrapper && !empty) {
                return existingSendingWrapper
            }

            const sendingWrapper = document.createElement('div')
            sendingWrapper.classList.add('paymentAndSending__sending')
            sendingWrapper.innerHTML = '<h2 class="paymentAndSending__title">Verzending</h2>'
            parent.prepend(sendingWrapper)
            return sendingWrapper
        }

        const paymentAndSendingWrapper = getPaymentAndSendingWrapper()

        const sendingContent = html.querySelectorAll('.woocommerce-shipping-methods')
        const sendingWrapper = getSendingWrapper(paymentAndSendingWrapper, sendingContent.length !== 0)
        if (sendingContent) {
            sendingWrapper.appendChild(sendingContent[0])
        }

        const paymentWrapper = getPaymentWrapper(paymentAndSendingWrapper)
        const paymentContent = html.querySelector('.woocommerce-checkout-payment')
        if (paymentContent) {
            paymentWrapper.appendChild(paymentContent)
        }

        const shippingTotals = html.querySelector('.woocommerce-shipping-totals')
        const selectedShipping = html.querySelector('.shipping_method:checked')
        const amount = jQuery(selectedShipping).closest('li').find('.woocommerce-Price-amount')
        if (amount.length) {
            jQuery(shippingTotals).show().find('td').html(amount.clone())
        } else {
            jQuery(shippingTotals).hide()
        }
    }

    return {
        attach,
    }
})()

//On ajax complete
jQuery(document).ajaxComplete(() => {
    paymentAndSendingMethods.attach(document.body)
})
