import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'
import {Sticky} from 'sticky-ts'

const BASE_CLASS = 'header'

const HeaderBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const block = context.querySelector<HTMLElement>(`.${BASE_CLASS}`)

        if (!block) {
            return
        }

        new Sticky(`.${BASE_CLASS}`)
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(HeaderBehaviour)
