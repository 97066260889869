import {behaviours} from '../behaviours/behaviour'

behaviours.addBehaviour(
    (() => {
        const getAttributeForBlock = function (block: Element, attribute: string) {
            if (!block) {
                return null
            }
            if (!block.hasAttribute(attribute)) {
                return null
            }
            return block.getAttribute(attribute)
        }

        const getBackgroundForBlock = function (block: Element) {
            const background = getAttributeForBlock(block, 'data-background')
            return background && background !== 'none' ? background : false
        }

        const attach = function () {
            document.querySelectorAll<Element>('div[data-type]').forEach((block) => {
                if (!block.nextElementSibling) {
                    return
                }

                const nextBlockBackground = getBackgroundForBlock(block.nextElementSibling)
                const nextBlockType = getAttributeForBlock(block.nextElementSibling, 'data-type')
                const nextBlockBackgroundImage = getAttributeForBlock(block.nextElementSibling, 'data-background-image')

                if (nextBlockBackground) {
                    block.setAttribute('data-next-background', nextBlockBackground)
                }

                if (nextBlockBackgroundImage) {
                    block.setAttribute('data-next-background-image', nextBlockBackgroundImage)
                }

                if (nextBlockType) {
                    block.setAttribute('data-next-type', nextBlockType)
                }
            })
        }

        return {attach}
    })()
)
