import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'
import {Thumbs} from 'swiper/modules'
import 'swiper/css'

const blockCarouselBehaviour: Behaviour = (function () {
    const BASE_CLASS = 'imageGallery'

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>(`.${BASE_CLASS}`)

        if (blocks.length === 0) {
            return
        }

        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, module))
        })
    }

    const init = function (block: HTMLElement, module: typeof import('swiper')) {
        const Swiper = module.default
        const carouselElement = block.querySelector<HTMLElement>(`.${BASE_CLASS}__slider`)
        const thumbnailsElement = block.querySelector<HTMLElement>(`.${BASE_CLASS}__thumbs`)

        if (!carouselElement || !thumbnailsElement) {
            return false
        }

        const thumbs = new Swiper(thumbnailsElement, {
            spaceBetween: 15,
            slidesPerView: 'auto',
            speed: 400,
            watchSlidesProgress: true,
        })

        new Swiper(carouselElement, {
            effect: 'fade',
            speed: 400,
            modules: [Thumbs],
            spaceBetween: 15,
            slidesPerView: 1,
            thumbs: {
                swiper: thumbs,
                slideThumbActiveClass: `${BASE_CLASS}__thumb--active`,
                autoScrollOffset: 2,
            },
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockCarouselBehaviour)
