import {behaviours} from '../../../global/scripts/behaviours/behaviour'
import {throttle} from 'lodash'

const blockProductSlider = (function () {
    const BASE_CLASS = 'blockText'

    const getAttributeForBlock = function (block: Element, attribute: string) {
        if (!block) {
            return null
        }
        if (!block.hasAttribute(attribute)) {
            return null
        }
        return block.getAttribute(attribute)
    }

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>(`.${BASE_CLASS}`)

        if (blocks.length === 0) {
            return
        }

        const throttledInit = throttle(
            () => {
                blocks.forEach((block) => init(block))
            },
            500,
            {leading: true}
        )

        throttledInit()
        window.addEventListener('resize', throttledInit)
    }

    /**
     * Get the height of the previous block if it is an image slider with no background
     */
    const getPreviousBlockHeight = function (block: HTMLElement) {
        const previousBlock = block.previousElementSibling

        if (!previousBlock) {
            return 0
        }

        const previousBlockType = getAttributeForBlock(previousBlock, 'data-type')
        const previousBlockBackground = getAttributeForBlock(previousBlock, 'data-background')

        if (previousBlockType !== 'image-slider' || previousBlockBackground !== 'none') {
            return 0
        }

        return previousBlock.querySelector('.blockImageSlider__slider')?.clientHeight || 0
    }

    /**
     * Get the height of the next block if it is an image teaser block with a blue background
     */
    const getNextBlockHeight = function (block: HTMLElement) {
        const nextBlock = block.nextElementSibling

        if (!nextBlock) {
            return 0
        }

        const nextBlockType = getAttributeForBlock(nextBlock, 'data-type')
        const nextBlockBackground = getAttributeForBlock(nextBlock, 'data-background')

        if (nextBlockType !== 'image-teasers' || nextBlockBackground !== 'blue') {
            return 0
        }

        return nextBlock.clientHeight
    }

    /**
     * Extend the background element to the previous and next block
     */
    const init = function (block: HTMLElement) {
        const backgroundImageElement = block.querySelector<HTMLElement>(`.${BASE_CLASS}__backgroundImage`)
        const backgroundElement = block.querySelector<HTMLElement>(`.${BASE_CLASS}__background`)

        if (!backgroundElement || !backgroundImageElement) {
            return false
        }

        const previousHeight = getPreviousBlockHeight(block)
        const nextHeight = getNextBlockHeight(block)

        if (previousHeight === 0 && nextHeight === 0) {
            return false
        }

        backgroundElement.style.top = `-${previousHeight / 2}px`
        backgroundElement.style.height = `calc(100% + ${previousHeight / 2 + nextHeight / 2}px)`
        backgroundElement.style.zIndex = '2'
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockProductSlider)
