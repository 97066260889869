import {floatLabels} from '../molecule/form/src/floatLabels'
import {Behaviour, behaviours} from '../../global/scripts/behaviours/behaviour'
import {textAreaAutoGrow} from '../molecule/form/src/autoGrow'

const checkoutFormBehavior: Behaviour = (() => {
    const attach = (context: HTMLElement) => {
        context
            .querySelectorAll<HTMLFormElement>('form.woocommerce-checkout, form.woocommerce-form-login, form.woocommerce-form-register, .woocommerce-MyAccount-content form')
            .forEach((form) => {
                floatLabels.initForm(form)
                textAreaAutoGrow(form)
            })
    }

    return {
        attach,
    }
})()

behaviours.addBehaviour(checkoutFormBehavior)
