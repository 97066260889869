import Accordion from 'accordion-js'
import {behaviours} from '../../../global/scripts/behaviours/behaviour'

behaviours.addBehaviour(
    (() => {
        const attach = function (context: HTMLElement) {
            const baseClass = 'accordion'
            context.querySelectorAll<HTMLElement>(`.${baseClass}`).forEach((element) => {
                new Accordion(element, {
                    duration: 400,
                    ariaEnabled: false,
                    showMultiple: false,
                    elementClass: `${baseClass}__item`,
                    triggerClass: `${baseClass}__title`,
                    panelClass: `${baseClass}__content`,
                    activeClass: `${baseClass}__item--active`,
                    openOnInit: [0],
                })
            })
        }

        return {
            attach: attach,
        }
    })()
)
