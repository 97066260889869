import {hasTouch} from '../../../../global/scripts/hasTouch'
import './styledSelect.scss'

export interface StyledSelectOptions {
    unsetSelectedOption?: boolean
}

const defaultOptions: StyledSelectOptions = {
    unsetSelectedOption: false,
}

export const styledSelect = (() => {
    const initForm = (form: HTMLFormElement) => {
        form.querySelectorAll<HTMLSelectElement>('select').forEach((select) => init(select))
    }

    const init = (select: HTMLSelectElement, options?: StyledSelectOptions) => {
        let placeholder: string = ''

        options = {...defaultOptions, ...options}

        // Create jquery object
        const $select = jQuery(select)

        const parent = select.parentElement!
        const $parent = jQuery(parent)

        if (select.classList.contains('disable-select2') || select.classList.contains('sf-input-select')) {
            return
        }

        if (!parent.id) {
            parent.id = `select-${new Date().valueOf()}`
        }

        if (selectHasPlaceholder(select)) {
            const option = document.createElement('option')
            select.prepend(option)
            placeholder = ''
        } else {
            const options = select.querySelectorAll<HTMLOptionElement>('option')
            options[0].disabled = hasTouch() && select.getAttribute('aria-required') !== 'true'
            placeholder = options[0].text
        }

        if (!hasTouch()) {
            const defaultValue = getSelectedOption(select)
            if (defaultValue && options.unsetSelectedOption) {
                select.value = ''
            }

            $parent.addClass('styledSelect')

            $select.select2({
                disabled: elementIsDisabled(select),
                minimumResultsForSearch: 100,
                dropdownParent: $parent,
                placeholder: placeholder,
            })
        }
    }

    const getSelectedOption = (select: HTMLSelectElement) => {
        const options = select.querySelectorAll<HTMLOptionElement>('option')
        return options[select.selectedIndex]
    }

    const elementIsDisabled = (select: HTMLSelectElement) => {
        if (select.classList.contains('sf-input-select') && select.querySelectorAll('option').length <= 1) {
            return true
        }
        return select.hasAttribute('disabled')
    }

    const selectHasPlaceholder = (select: HTMLElement) => {
        const options = select.querySelectorAll<HTMLOptionElement>('option')

        if (!options.length) {
            return false
        }

        // Placeholder contains gravity forms placeholder class
        if (!options[0].classList.contains('gf_placeholder')) {
            return false
        }

        // Placeholder is not empty, this is required for a placeholder
        if (options[0].value !== '') {
            return false
        }

        if (select.getAttribute('aria-required') !== 'true') {
            return false
        }
    }

    return {
        init,
        initForm,
    }
})()
