import {behaviours} from '../../../global/scripts/behaviours/behaviour'
import {Navigation} from 'swiper/modules'

const blockProductSlider = (function () {
    const BASE_CLASS = 'blockProductSlider'

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>(`.${BASE_CLASS}`)

        if (blocks.length === 0) {
            return
        }

        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, module))
        })
    }

    const init = function (block: HTMLElement, module: typeof import('swiper')) {
        const Swiper = module.default
        const sliderElement = block.querySelector<HTMLElement>(`.${BASE_CLASS}__slider`)
        const wrapperClass = `${BASE_CLASS}__items`
        const slideClass = `${BASE_CLASS}__item`

        // Navigation
        const navClass = 'slideNavigation__item'
        const buttonNext = block?.querySelector<HTMLElement>(`.${navClass}--next`)
        const buttonPrev = block?.querySelector<HTMLElement>(`.${navClass}--previous`)

        if (!sliderElement) {
            return false
        }

        //get total number of slider items
        new Swiper(sliderElement, {
            modules: [Navigation],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: 'auto',
            spaceBetween: 24,
            slideActiveClass: `${BASE_CLASS}__item--active`,
            slideVisibleClass: `${BASE_CLASS}__item--visible`,
            threshold: 5,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                1024: {
                    spaceBetween: 40,
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockProductSlider)
