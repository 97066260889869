import {behaviours} from '../../../global/scripts/behaviours/behaviour'
import type {Swiper} from 'swiper/types'
import {Navigation} from 'swiper/modules'

let activeSwipers: Swiper[] = []

const blockInspiration = (function () {
    const BASE_CLASS = 'blockInspiration'
    const mediaQuery = window.matchMedia('(max-width: 1024px)')

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>(`.${BASE_CLASS}`)

        if (blocks.length === 0) {
            return
        }

        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            initAll(blocks, module)

            mediaQuery.addEventListener('change', () => {
                if (mediaQuery.matches) {
                    initAll(blocks, module)
                } else {
                    activeSwipers.forEach((swiper) => {
                        swiper.destroy()
                    })
                    activeSwipers = []
                }
            })
        })
    }

    const initAll = function (blocks: NodeListOf<HTMLElement>, module: typeof import('swiper')) {
        blocks.forEach((block) => {
            const swiper = init(block, module)
            if (swiper) {
                activeSwipers.push(swiper)
            }
        })
    }

    const init = function (block: HTMLElement, module: typeof import('swiper')): Swiper | null {
        const Swiper = module.default
        const sliderElement = block.querySelector<HTMLElement>(`.${BASE_CLASS}__slider`)
        const wrapperClass = `${BASE_CLASS}__items`
        const slideClass = `${BASE_CLASS}__item`

        // Navigation
        // const navClass = 'slideNavigation__item'
        // const buttonNext = block?.querySelector<HTMLElement>(`.${navClass}--next`)
        // const buttonPrev = block?.querySelector<HTMLElement>(`.${navClass}--previous`)

        if (!sliderElement) {
            return null
        }

        //get total number of slider items
        return new Swiper(sliderElement, {
            init: mediaQuery.matches,
            modules: [Navigation],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: 'auto',
            spaceBetween: 24,
            slideActiveClass: `${BASE_CLASS}__item--active`,
            slideVisibleClass: `${BASE_CLASS}__item--visible`,
            threshold: 5,
            // navigation: {
            //     nextEl: buttonNext,
            //     prevEl: buttonPrev,
            //     disabledClass: `${navClass}--disabled`,
            //     hiddenClass: `${navClass}--hidden`,
            //     lockClass: `${navClass}--lock`,
            // },
            breakpoints: {
                1024: {
                    spaceBetween: 40,
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockInspiration)
