export const textAreaAutoGrow = (html: HTMLElement) => {
    html.querySelectorAll('textarea').forEach((textarea) => {
        const onInput = function () {
            textarea.style.height = 'auto'
            textarea.style.height = textarea.scrollHeight + 20 + 'px'
        }

        textarea.rows = 5
        textarea.style.height = 'auto'
        textarea.style.height = textarea.scrollHeight + 20 + 'px'
        textarea.style.overflowY = 'hidden'

        textarea.addEventListener('input', onInput, false)

        setTimeout(onInput, 200)
    })
}
