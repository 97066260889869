import {Sticky} from 'sticky-ts'
import {Behaviour, behaviours} from '../../global/scripts/behaviours/behaviour'

const wcBlocks: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.wc-block-components-sidebar')

        if (blocks.length === 0) {
            return
        }

        new Sticky('.wc-block-components-sidebar', {
            stickyContainer: '.wc-block-components-sidebar-layout',
            marginTop: 30,
            stickyFor: 1024,
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(wcBlocks)
