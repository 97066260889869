import 'plyr/dist/plyr.css'
import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'
import Plyr from 'plyr'

type initVideoInterface = (
    element: HTMLElement,
    module: {
        default: typeof Plyr
    }
) => void

const initVideo: initVideoInterface = function (element: HTMLElement, module) {
    const video = element.querySelector<HTMLElement>('.plyr__video-embed')
    const autoplay = element.getAttribute('data-autoplay') === 'true'
    const background = element.getAttribute('data-background') === 'true'
    const parent = element.closest('[data-autoplay-video-on-open]')

    const fullVideo = element.querySelector('.video__fillVideo')

    if (!video) {
        return
    }

    if (fullVideo) {
        const calculateAspectRatio = () => {
            const widerThen16by9 = element.clientWidth > (element.clientHeight * 16) / 9
            element.classList.toggle('video--wide', widerThen16by9)
        }

        window.addEventListener('resize', calculateAspectRatio)
        calculateAspectRatio()
    }

    let playerConfig: Plyr.Options = {
        clickToPlay: undefined,
    }

    if (background) {
        playerConfig = {
            ...playerConfig,
            controls: [],
            fullscreen: {
                enabled: false,
                fallback: false,
            },
            vimeo: {
                background: background,
            },
            loop: {
                active: true,
            },
        }
    }

    if (autoplay) {
        playerConfig.clickToPlay = false
    }

    const player = new module.default(video, playerConfig)

    if (autoplay) {
        player.on('ready', () => {
            player.muted = background
            player.play()
        })

        player.on('playing', () => {
            document.dispatchEvent(new CustomEvent('video-autoplayed', {detail: {player: player}}))
        })
    }

    if (parent) {
        parent.addEventListener('opened', () => {
            player.muted = false
            player.play()
        })
        parent.addEventListener('closed', () => {
            player.pause()
        })
    }
}

const VideoBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const videos = context.querySelectorAll<HTMLElement>('.video')

        if (videos.length === 0) {
            return false
        }

        import(/* webpackChunkName: "video" */ 'plyr').then((module) => {
            videos.forEach((video) => initVideo(video, module))
        })
    }
    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(VideoBehaviour)
