import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'

const Hero: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        context.querySelectorAll<HTMLElement>('.hero').forEach((hero) => {
            const video = hero.querySelector<HTMLElement>('.video[data-autoplay]')
            if (video) {
                document.addEventListener('video-autoplayed', () => {
                    hero.classList.add('hero--videoStarted')
                })
            }
        })
    }
    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(Hero)
